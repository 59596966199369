// extracted by mini-css-extract-plugin
export var BusinessName = "Dashboard-module--BusinessName--c4cbf";
export var Button = "Dashboard-module--Button--5acf2";
export var Capitalize = "Dashboard-module--Capitalize--e8431";
export var CenterAligned = "Dashboard-module--CenterAligned--e1b4b";
export var DownloadButton = "Dashboard-module--DownloadButton--315b7";
export var EntryRow1 = "Dashboard-module--EntryRow1--2a209";
export var EntryRow2 = "Dashboard-module--EntryRow2--f731e";
export var EntryWrapper = "Dashboard-module--EntryWrapper--d0653";
export var Finalise = "Dashboard-module--Finalise--3892d";
export var HeroName = "Dashboard-module--HeroName--e4df3";
export var Intro = "Dashboard-module--Intro--39bd4";
export var JobTitle = "Dashboard-module--JobTitle--e5480";
export var LoginToggleBoxes = "Dashboard-module--LoginToggleBoxes--81e5c";
export var LoginToggles = "Dashboard-module--LoginToggles--88340";
export var Right = "Dashboard-module--Right--c12b1";
export var SmallText = "Dashboard-module--SmallText--fbaf4";
export var ToggleBoxActive = "Dashboard-module--ToggleBoxActive--9995b";
export var ToggleContent = "Dashboard-module--ToggleContent--d926a";