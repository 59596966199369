import DashboardWrapper from '../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapper'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import * as DashboardStyles from '../women-in-business/dashboard/Dashboard.module.scss'
import getEntries from '../../api/women-in-business/get-entries'
import { FormFields } from '../../components/BestSchoolsAwards/Form/Form'
import { LoginContext } from '../../lib/utils'

const TideThankYou = () => {
  const { usr } = useContext(LoginContext)
  const [foundEntry, setFoundEntry] = useState<FormFields>()
  const [entrySubmitted, setEntrySubmitted] = useState<boolean>(true)

  const checkForEntries = async (emailAddr: string) => {
    try {
      await getEntries(emailAddr)
        .then(res => res.data)
        .then(data => {
          setFoundEntry(data)
        })
    } catch (e: any) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (usr?.viewer?.email) {
      checkForEntries(usr.viewer.email)
    }
  }, [usr])

  useEffect(() => {
    if (foundEntry?.have_confirmed_tide_account) {
      setEntrySubmitted(true)
    } else {
      setEntrySubmitted(false)
    }
  }, [foundEntry])
  return (
    <>
      <Helmet>
        <title>
          Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          {entrySubmitted ? (
            <>
              <h1>Thank you for entering!</h1>
              <p>Thanks so much for entering the SHE MEANS BUSINESS Award.</p>
              <p>
                <strong>Your application has landed! </strong>
              </p>
              <p>
                We&apos;ll be announcing the 3 lucky finalists on 29 November,
                so watch this space.{' '}
              </p>
              <p>
                In the meantime, discover the best of what&apos;s happening near
                you on the{' '}
                <a href={`https://muddystilettos.co.uk/`}>
                  Muddy Stilettos homepage
                </a>
                .
              </p>
              <p>Best wishes</p>
              <img
                src={`https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/Hero_Sig.jpg`}
              />
              <p className={DashboardStyles.HeroName}>Hero Brown</p>
              <p className={DashboardStyles.JobTitle}>
                Founder and Editor-in-Chief
              </p>
            </>
          ) : (
            <>
              <h1>Oops!</h1>
              <p>
                It doesn&apos;t appear as though you have submitted an entry.
              </p>
              <p>
                Please visit the{' '}
                <a href={`/women-in-business/dashboard/`}>Get Started</a> page
                to get your entry in.
              </p>
            </>
          )}
        </div>
      </DashboardWrapper>
    </>
  )
}

export default TideThankYou
