const getEntries = async (email: string) => {
  const emailData = new FormData()
  emailData.append('email', email)
  const url = 'https://dev.muddystilettos.co.uk/wp-json/muddy/wib-entries/'
  return await fetch(url, {
    method: 'post',
    body: emailData
  })
    .then(response => {
      return response.json()
    })
    .catch(e => {
      return e.message
    })
}

export default getEntries
