// extracted by mini-css-extract-plugin
export var BurgerMenu = "Nav-module--BurgerMenu--79b3f";
export var BurgerMenuOpen = "Nav-module--BurgerMenuOpen--d1e9b";
export var BurgerMenuPink = "Nav-module--BurgerMenuPink--46b94";
export var BurgerMenu_burger = "Nav-module--BurgerMenu_burger--a01cf";
export var BurgerMenu_burger__line = "Nav-module--BurgerMenu_burger__line--a8bc9";
export var Dark = "Nav-module--Dark--cb102";
export var IsMobile = "Nav-module--IsMobile--af1fb";
export var Light = "Nav-module--Light--db646";
export var Link = "Nav-module--Link--ae7d6";
export var Menu_burger__line = "Nav-module--Menu_burger__line--12260";
export var Nav = "Nav-module--Nav--54a34";
export var Visible = "Nav-module--Visible--0e059";