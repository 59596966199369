import React, { useState, useEffect } from 'react'
import * as NavStyles from './Nav.module.scss'
import classNames from 'classnames'

interface IBurgerProps {
  open: boolean
  setOpen: (open: boolean) => void
  theme?: 'light' | 'dark'
}

const Burger = ({ open, setOpen, theme }: IBurgerProps) => {
  const [transition, setTransition] = useState(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setTransition(true)
      }, 1200)
    } else {
      setTransition(false)
    }
  }, [open])
  return (
    <div
      onClick={() => setOpen(!open)}
      className={classNames({
        [NavStyles.BurgerMenu]: true,
        [NavStyles.BurgerMenuOpen]: transition,
        [NavStyles.BurgerMenuPink]: theme && theme !== 'light' && !open
      })}
    >
      <div className={NavStyles.BurgerMenu_burger}>
        <div className={NavStyles.BurgerMenu_burger__line}></div>
        <div className={NavStyles.BurgerMenu_burger__line}></div>
        <div className={NavStyles.BurgerMenu_burger__line}></div>
      </div>
    </div>
  )
}

export default Burger
