import React, { useContext, useEffect, useState } from 'react'
import * as NavStyles from './Nav.module.scss'
import classNames from 'classnames'
import Burger from './Burger'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../../lib/utils'

export const menu = [
  {
    uri: '/best-schools-awards/',
    label: 'Home'
  },
  {
    uri: '/best-schools-awards/the-judges/',
    label: 'The Judges'
  },
  {
    uri: '/best-schools-awards/the-categories/',
    label: 'The Categories'
  },
  {
    uri: '/best-schools-awards/sponsor/',
    label: 'Sponsor'
  },
  {
    uri: '/best-schools-awards/faqs/',
    label: 'FAQs'
  },
  {
    uri: '/best-schools-awards/terms-conditions/',
    label: 'T&Cs'
  },
  {
    uri: '/best-schools-awards/pricing/',
    label: 'Pricing'
  },
  {
    uri: '/best-schools-awards/dashboard/',
    label: 'Get Started'
  }
]

const tideMenu = [
  {
    uri: '/women-in-business/',
    label: 'The Competition'
  },
  {
    uri: '/women-in-business/winner/',
    label: 'The Winner'
  },
  {
    uri: '/women-in-business/about-tide/',
    label: 'About Tide'
  },
  {
    uri: '/women-in-business/how-it-works/',
    label: 'How it Works'
  },
  {
    uri: '/women-in-business/faq/',
    label: 'FAQs'
  },
  {
    uri: '/women-in-business/terms-conditions/',
    label: 'T&Cs'
  },
  {
    uri: '/women-in-business/dashboard/',
    label: 'My Dashboard'
  }
]

interface NavProps {
  theme: 'light' | 'dark'
  site: 'school' | 'tide'
}

const Nav = ({ theme, site }: NavProps) => {
  const [menuToUse, setMenuToUse] = useState(
    site === 'school' ? menu : tideMenu
  )
  const [visible, setVisible] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
  const SchoolContext = useContext(SchoolAwardsContext)

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        if (window.innerWidth < 1024) {
          setIsMobile(true)
        } else {
          setIsMobile(false)
        }
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      handleResize()
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  useEffect(() => {
    if (SchoolContext === SchoolAwardsStates.OPEN) {
      setMenuToUse(menu)
    } else if (
      SchoolContext === SchoolAwardsStates.FINALISTS ||
      SchoolContext === SchoolAwardsStates.WINNERS
    ) {
      const resultsMenu = menu.filter(
        menuItem => menuItem.label !== 'Get Started'
      )

      const resultItem = {
        uri: '/best-schools-awards/results/',
        label: 'Results'
      }

      resultsMenu.splice(3, 0, resultItem)

      setMenuToUse(resultsMenu)
    } else {
      const closedMenu = menu.filter(
        menuItem => menuItem.label !== 'Get Started'
      )
      setMenuToUse(closedMenu)
    }
  }, [SchoolContext])

  useEffect(() => {
    if (isMobile) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }, [isMobile])

  return (
    <>
      {isMobile && (
        <Burger open={isMobile && visible} setOpen={setVisible} theme={theme} />
      )}
      <nav
        className={classNames({
          [NavStyles.Nav]: true,
          [NavStyles.Light]: theme === 'light',
          [NavStyles.Dark]: theme === 'dark',
          [NavStyles.Visible]: isMobile ? visible : false,
          [NavStyles.IsMobile]: isMobile
        })}
        aria-label="Main"
      >
        <ul>
          {menuToUse.map((menuItem, i) => (
            <li style={{ order: i }} key={menuItem.uri}>
              <a className={NavStyles.Link} href={menuItem.uri || ''}>
                {menuItem.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}

export default Nav
